import APIManager from './APIManager';

// ? Fill up Individual KYC, NRB, and TMS forms
const FillIndividualKYCForm = async (queryObj) => {
    // ? Flattening the object for form data
    let occupationDetail = queryObj.clientDetail.occupationDetail;
    delete queryObj.clientDetail.occupationDetail;
    let currentAddress = queryObj.address.currentAddress;
    let permanentAddress = queryObj.address.permanentAddress;
    let familyDetails = queryObj.familyMembers;
    let documentDetails = queryObj.documents;
    let requiredData = {
        ...occupationDetail,
        ...queryObj.clientDetail,
        ...currentAddress,
        ...permanentAddress,
        ...familyDetails,
        ...queryObj.bankAccount,
        ...documentDetails,
    };
    const splitDateAD = requiredData.dobInAD.split('-');
    requiredData.dobADYear = splitDateAD[0];
    requiredData.dobADMonth = splitDateAD[1];
    requiredData.dobADDate = splitDateAD[2];
    const splitDateBS = requiredData.dobInBS.split('-');
    requiredData.dobBSYear = splitDateBS[0];
    requiredData.dobBSMonth = splitDateBS[1];
    requiredData.dobBSDate = splitDateBS[2];
    const formData = new FormData();
    for (const key in requiredData) {
        if (Object.prototype.hasOwnProperty.call(requiredData, key)) {
            formData.append(key, requiredData[key]);
        }
    }

    const formResponse = APIManager.axios
        .post(`/api/v1/public/individual-kyc`, formData)
        .then((response) => {
            return response;
        });

    return formResponse;
};

export default {
    FillIndividualKYCForm,
};
