<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <!-- !  Basic Details -->
      <!-- ? Label -->
      <v-row dense justify="center" class="mb-4">
        <v-col cols="12" sm="10" md="10">
          <span class="text--darken-3 inputTitle">Basic Details</span>
        </v-col>
      </v-row>
      <!-- ? Branch -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <div class="mb-2">
            <span class="grey--text text--darken-3 inputLabel">Branch (शाखा)</span>
          </div>
          <v-select v-model="clientDetail.branchName" :items="branches" item-text="text" item-value="value"
            placeholder="Select Branch (शाखा)" outlined dense :rules="stringRules"></v-select>
        </v-col>
      </v-row>

      <!-- ?  Text fields -->
      <!-- ? Full Name in Nepali -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <div class="mb-2">
            <span class="text--darken-3 inputLabel">नाम</span>
          </div>

          <v-text-field placeholder="e.g. राम बहादुर अमात्य " density="compact" :rules="stringRules"
            v-model="clientDetail.fullNameNP"></v-text-field>
        </v-col>
      </v-row>

      <v-row dense justify="center">
        <!-- ? Full Name -->
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">Full name</span>
            </div>

            <v-text-field placeholder="e.g. Ram Bahadur Maharjan" density="compact" :rules="stringRules"
              v-model="clientDetail.fullName"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ? Date Of Birth -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <DatePicker label="Date of Birth (जन्म मिति) in A.D." type="AD" emitName="changeADdate"
            placeholder="A.D. (ई.स.)" @changeADdate="changeADdate"></DatePicker>
        </v-col>
      </v-row>

      <v-row dense justify="center" class="mt-n6">
        <v-col cols="12" sm="10" md="10">
          <span style="color: #f44336" v-if="ADerror">* Required</span>
        </v-col>
      </v-row>

      <!-- ? DOB IN BS -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <DatePicker label="Date of Birth (जन्म मिति) in B.S." type="BS" emitName="changeBSdate"
            placeholder="B.S. (बि.स.)" @changeBSdate="changeBSdate"></DatePicker>
        </v-col>
      </v-row>

      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <span style="color: #f44336" v-if="BSerror">* Required</span>
        </v-col>
      </v-row>

      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <div class="mb-2">
            <span class="grey--text text--darken-3 inputLabel">Gender</span>
          </div>
          <v-select v-model="clientDetail.gender" :items="gender" item-text="text" item-value="value"
            placeholder="Select Gender (लिङ्ग)" outlined dense :rules="stringRules"></v-select>
        </v-col>
      </v-row>

      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <div class="mb-4">
            <span class="grey--text text--darken-3 inputLabel">Nationality (राष्ट्रियता)</span>
          </div>

          <v-autocomplete v-model="clientDetail.nationality" :items="nationalities" outlined dense :rules="stringRules"
            label="Select Nationality"></v-autocomplete>
        </v-col>
      </v-row>

      <!-- ?  Citizenship Number -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Citizenship Number
              </span>
            </div>

            <v-text-field dense placeholder="e.g. 12-23-21-3445" density="compact" v-model="clientDetail.citizenshipNo"
              :rules="stringRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Issue District -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Issue District
              </span>
            </div>

            <v-text-field dense placeholder="e.g. Lalitpur" density="compact" v-model="clientDetail.issueDistrict"
              :rules="stringRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ? Issue Date-->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <DatePicker label="Issue Date in B.S." type="BS" emitName="changeIssueDate" placeholder="B.S. (बि.स.)"
            @changeIssueDate="changeIssueDate"></DatePicker>
        </v-col>
      </v-row>

      <v-row dense justify="center" class="mt-n1" v-if="IssueDateError">
        <v-col cols="12" sm="10" md="10">
          <span style="color: #f44336">* Required</span>
        </v-col>
      </v-row>

      <!-- ?  Beneficiary Number -->
      <v-row dense justify="center" class="mt-7">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                DEMAT Number (Beneficiary Number)
              </span>
            </div>

            <v-text-field placeholder="e.g. 12-23-21-3445" density="compact"
              v-model="clientDetail.beneficiaryIDNo"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Passport Number-->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <div class="mb-2">
            <span class="text--darken-3 inputLabel">Passport number</span>
          </div>

          <v-text-field placeholder="e.g. 1021221212" density="compact"
            v-model="clientDetail.passportNumber"></v-text-field>
        </v-col>
      </v-row>

      <!-- ?  PAN -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Permanent Account Number (PAN)
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. 197897187" density="compact"
              v-model="clientDetail.panNumber"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  NRN ID -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                गैरआवासीय नेपालीको हकमा परिचयपत्र नं
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. 197897187" density="compact"
              v-model="clientDetail.nrnId"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  NRN Address -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                गैरआवासीय नेपालीको हकमा परिचयपत्र ठेगाना
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. XXX" density="compact"
              v-model="clientDetail.nrnAddress"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- !  Occupation Details -->
      <!-- ? Label -->
      <v-row dense justify="center" class="mb-4">
        <v-col cols="12" sm="10" md="10">
          <span class="text--darken-3 inputTitle">पेसागत विवरण</span>
          <br />
          <span class="text--darken-3 inputTitle">Details of Occupation</span>
        </v-col>
      </v-row>

      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <div class="mb-2">
            <span class="grey--text text--darken-3 inputLabel">Occupation (पेशा)</span>
          </div>
          <v-select v-model="clientDetail.occupationDetail.occupation" :items="occupation" item-text="text"
            item-value="value" placeholder="Select Occupation" outlined dense :rules="stringRules"></v-select>
        </v-col>
      </v-row>

      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <div class="mb-2">
            <span class="grey--text text--darken-3 inputLabel">Type of Business (व्यापारको प्रकार)</span>
          </div>
          <v-select v-model="clientDetail.occupationDetail.typeOfBusiness" :items="businessTypes" item-text="text"
            item-value="value" placeholder="Select business type" outlined dense></v-select>
        </v-col>
      </v-row>

      <!-- ?  Organization Name -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Organization’s Name (संस्थाको नाम)
              </span>
            </div>

            <v-text-field placeholder="e.g. Acme Pvt. Ltd." v-model="clientDetail.occupationDetail.organizationName" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Organization Address -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Organization’s Address (ठेगाना)
              </span>
            </div>

            <v-text-field placeholder="e.g. Bakhundole, Lalitpur"
              v-model="clientDetail.occupationDetail.organizationAddress" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Designation-->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Designation (पद)
              </span>
            </div>

            <v-text-field placeholder="e.g. Accountant" v-model="clientDetail.occupationDetail.organizationDesignation" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Employee Id-->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Employee Id (कर्मचारीको हकमा कर्मचारी परिचयपत्र नं.)
              </span>
            </div>

            <v-text-field placeholder="e.g. 12321" v-model="clientDetail.occupationDetail.employeeIDNo" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Financial Details-->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Financial Details (आर्थिक विवरण)
              </span>
              <br />

              <span class="grey--text text--darken-3 inputLabel">
                आयको सिमा (आर्थिक विवरण / Income Limit (Annual Details)
              </span>
            </div>

            <v-select v-model="clientDetail.occupationDetail.incomeLimit" :items="incomeLimits" item-text="text"
              item-value="value" placeholder="Select Income Bracket" outlined dense :rules="stringRules"></v-select>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Involvement in Investment Companies which were established for securities trading-->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Involvement in Investment Companies which were established for
                securities trading
              </span>
              <br />
              <span class="grey--text text--darken-3 inputLabel">
                धितोपत्र खरिद विक्री गर्ने मुख्य उद्देश्यले स्थापित इन्भेष्मेन्ट
                कम्पनिमा संग्लग्न
              </span>
              <br />

              <v-radio-group v-model="clientDetail.occupationDetail.investmentCompanyInvolvement
                " column>
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </div>
          </section>
        </v-col>
      </v-row>

      <div v-if="clientDetail.occupationDetail.investmentCompanyInvolvement">
        <!-- ?  Investment Company Name-->
        <v-row dense justify="center">
          <v-col cols="12" sm="10" md="10">
            <section class="pb-2">
              <div class="mb-2">
                <span class="grey--text text--darken-3 inputLabel">
                  Investment Company Name
                </span>
              </div>

              <v-text-field placeholder="e.g. Acme Stock Broking Company Pvt. Ltd."
                v-model="clientDetail.occupationDetail.investmentCompanyName" :rules="stringRules" />
            </section>
          </v-col>
        </v-row>

        <!-- ? Designation in Investment Company -->
        <v-row dense justify="center">
          <v-col cols="12" sm="10" md="10">
            <section class="pb-2">
              <div class="mb-2">
                <span class="grey--text text--darken-3 inputLabel">
                  Designation in Investment Company
                </span>
              </div>

              <v-select v-model="clientDetail.occupationDetail.investmentCompanyDesignation
                " :items="designations" item-text="text" item-value="value" placeholder="Select Designation" outlined
                dense :rules="stringRules"></v-select>
            </section>
          </v-col>
        </v-row>
      </div>

      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <v-btn color="success" @click="validate">Next</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { nationalities } from '@/config/nationalities';
import DatePicker from '@/components/DatePicker.vue';

export default {
  name: 'ClientDetails',
  components: {
    DatePicker,
  },
  data() {
    return {
      menu2: false,
      valid: true,
      BSerror: false,
      ADerror: false,
      IssueDateError: false,
      gender: [
        { text: 'Male (पुरुष)', value: 'Male' },
        { text: 'Female (महिला)', value: 'Female' },
      ],
      branches: [
        { text: 'Kathmandu', value: 'Kathmandu' },
        { text: 'Itahari', value: 'Itahari' },
        { text: 'Charikot', value: 'Charikot' },
        { text: 'Dhulikhel', value: 'Dhulikhel' },
        { text: 'Bardibas', value: 'Bardibas' },
        { text: 'Birgunj', value: 'Birgunj' },
        { text: 'Bhairahawa', value: 'Bhairahawa' },
        { text: 'Beni', value: 'Beni' },
        { text: 'Surkhet', value: 'Surkhet' },
        { text: 'Hetauda', value: 'Hetauda' },
      ],
      nationalities: nationalities,
      clientDetail: {
        fullNameNP: '',
        passportNumber: '',
        branchName: '',
        fullName: '',
        dobInBS: '',
        dobInAD: '',
        gender: '',
        nationality: '',
        citizenshipNo: '',
        issueDistrict: '',
        issueDate: '',
        beneficiaryIDNo: '',
        panNumber: '',
        nrnId: '',
        nrnAddress: '',
        occupationDetail: {
          occupation: '',
          organizationName: '',
          organizationAddress: '',
          employeeIDNo: '',
          incomeLimit: '',
          typeOfBusiness: '',
          organizationDesignation: '',
          investmentCompanyInvolvement: '',
          investmentCompanyName: '',
          investmentCompanyDesignation: '',
        },
      },
      occupation: [
        { text: 'Service (सेवा)', value: 'Service' },
        { text: 'Government (सरकारी)', value: 'Government' },
        {
          text: 'Public/Private Sector (सामाजिक/ निजी क्षेत्र)',
          value: 'Public/ Private Sector',
        },
        {
          text: 'NGO/INGO (एन.जी.ओ./आई.न.जी.ओ)',
          value: 'NGO/INGO',
        },
        {
          text: 'Expert (विशेषज्ञ)',
          value: 'Expert',
        },
        {
          text: 'Businessperson (व्यापार)',
          value: 'Businessperson',
        },
        {
          text: 'Agriculture (कृषि)',
          value: 'Agriculture',
        },
        {
          text: 'Retired (सेवा निवृत्त)',
          value: 'Retired',
        },
        {
          text: 'Housewife (गृहिणी)',
          value: 'Housewife',
        },
        {
          text: 'Student (विद्यार्थी)',
          value: 'Student',
        },
        {
          text: 'Others (अन्य)',
          value: 'Others',
        },
      ],
      designations: [
        'Director',
        'Executive',
        'Share Holder',
        'Employee',
        'Others',
      ],
      incomeLimits: [
        {
          text: 'Upto Rs. 5,00,000 (रू. ५,००,००० सम्म)',
          value: '< 500000',
        },
        {
          text: 'From Rs. 5,00,001 to Rs. 10,00,000 (रू. ५,००,००१ देखि १०,००,००० सम्म)',
          value: '500001 < > 1000000',
        },
        {
          text: 'Above Rs. 10,00,001 (रू. १०,००,००१ भन्दा माथि)',
          value: '1000001 <',
        },
      ],
      businessTypes: [
        {
          text: 'Manufacturing (उत्पादन)',
          value: 'Manufacturing',
        },
        {
          text: 'Service Oriented (सेवामुखी)',
          value: 'Service Oriented',
        },
        {
          text: 'Others (अन्य)',
          value: 'Others',
        },
      ],
      stringRules: [(v) => !!v || 'Field is required'],
      floatRules: [
        (v) => !!v || 'Field is required',
        (v) => /[+-]?([0-9]*[.])?[0-9]+/.test(v) || 'Field must be a number',
      ],
      integerRules: [
        (v) => !!v || 'Field is required',
        (v) =>
          /^[+]?\d+?$/.test(v) || 'Field must be a number without decimals',
      ],
    };
  },
  methods: {
    changeADdate(value) {
      this.clientDetail.dobInAD = value;
    },
    changeBSdate(value) {
      this.clientDetail.dobInBS = value;
    },
    changeIssueDate(value) {
      this.clientDetail.issueDate = value;
    },
    validate() {
      if (this.clientDetail.dobInAD === null) {
        this.ADerror = true;
      } else {
        this.ADerror = false;
      }

      if (this.clientDetail.dobInBS === null) {
        this.BSerror = true;
      } else {
        this.BSerror = false;
      }

      if (this.clientDetail.issueDate === null) {
        this.IssueDateError = true;
      } else {
        this.IssueDateError = false;
      }

      if (
        this.$refs.form.validate() &&
        this.clientDetail.dobInAD !== null &&
        this.clientDetail.dobInBS !== null &&
        this.clientDetail.issueDate !== null
      ) {
        this.$emit('changeDetails', { data: this.clientDetail, type: "Client Details" });
        this.$emit('nextStep');
      } else {
        this.$emit('errorMessage', 'Invalid or missing fields');
      }
    },
  },
};
</script>

<style>
.inputLabel {
  font-weight: 600 !important;
  font-size: 0.9rem;
}

.inputTitle {
  font-weight: 600 !important;
  font-size: 1.2rem;
}
</style>
