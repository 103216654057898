<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-row dense justify="center" class="mb-4">
        <v-col cols="12" sm="10" md="10">
          <span class="text--darken-3 inputTitle">Documents Upload</span>
        </v-col>
      </v-row>

      <!-- ? Passport size photo -->
      <v-row dense justify="center">
        <v-col cols="12" md="10">
          <span class="text--darken-3 inputLabel">Client's Photo</span>
        </v-col>
        <v-col cols="12" md="10">
          <v-file-input accept="image/*" label="Upload Passport Size Photo in JPG or PNG format" solo flat
            density="compact" outlined append-icon="mdi-paperclip" prepend-icon="" class="text--blue" dense
            v-model="documents.photoPP" :rules="stringSizeRule"></v-file-input>
        </v-col>
      </v-row>

      <!-- ? Map -->
      <v-row dense justify="center">
        <v-col cols="12" md="10">
          <span class="text--darken-3 inputLabel">Map Photo</span>
        </v-col>
        <v-col cols="12" md="10">
          <v-file-input accept="image/*" label="Upload Map Photo in JPG or PNG format" solo flat density="compact"
            outlined append-icon="mdi-paperclip" prepend-icon="" class="text--blue" dense v-model="documents.map"
            :rules="stringSizeRule"></v-file-input>
        </v-col>
      </v-row>



      <!-- ? Client Signature -->
      <v-row dense justify="center">
        <v-col cols="12" md="10">
          <span class="text--darken-3 inputLabel">Client Signature</span>
        </v-col>
        <v-col cols="12" md="10">
          <v-file-input accept="image/*" label="Upload Client Signature in JPG or PNG format" solo flat density="compact"
            outlined append-icon="mdi-paperclip" prepend-icon="" class="text--blue" dense
            v-model="documents.clientSignature" :rules="stringSizeRule"></v-file-input>
        </v-col>
      </v-row>

      <!-- ? Right Thumb Print-->
      <v-row dense justify="center">
        <v-col cols="12" md="10">
          <span class="text--darken-3 inputLabel">Right Thumb Print</span>
        </v-col>
        <v-col cols="12" md="10">
          <v-file-input accept="image/*" label="Upload Right Thumb Print in JPG or PNG format" solo flat density="compact"
            outlined append-icon="mdi-paperclip" prepend-icon="" class="text--blue" dense
            v-model="documents.rightThumbPrint" :rules="stringSizeRule"></v-file-input>
        </v-col>
      </v-row>

      <!-- ? Left Thumb Print-->
      <v-row dense justify="center">
        <v-col cols="12" md="10">
          <span class="text--darken-3 inputLabel">Left Thumb Print</span>
        </v-col>
        <v-col cols="12" md="10">
          <v-file-input accept="image/*" label="Upload Left Thumb Print in JPG or PNG format" solo flat density="compact"
            outlined append-icon="mdi-paperclip" prepend-icon="" class="text--blue" dense
            v-model="documents.leftThumbPrint" :rules="stringSizeRule"></v-file-input>
        </v-col>
      </v-row>

      <!-- ? ID Certificate -->
      <v-row dense justify="center">
        <v-col cols="12" md="10">
          <span class="text--darken-3 inputLabel">Citizenship Image (Front)</span>
        </v-col>
        <v-col cols="12" md="10">
          <v-file-input accept="image/*" label="Upload Citizen Image in JPG or PNG format" solo flat density="compact"
            outlined append-icon="mdi-paperclip" prepend-icon="" class="text--blue" dense
            v-model="documents.idCertificateFront" :rules="stringSizeRule"></v-file-input>
        </v-col>
      </v-row>
      <!-- ? ID Certificate -->
      <v-row dense justify="center">
        <v-col cols="12" md="10">
          <span class="text--darken-3 inputLabel">Citizenship Image (Back)</span>
        </v-col>
        <v-col cols="12" md="10">
          <v-file-input accept="image/*" label="Upload Citizen Image in JPG or PNG format" solo flat density="compact"
            outlined append-icon="mdi-paperclip" prepend-icon="" class="text--blue" dense
            v-model="documents.idCertificateBack" :rules="stringSizeRule"></v-file-input>
        </v-col>
      </v-row>

      <!-- ? Guardian Signature -->
      <v-row dense justify="center">
        <v-col cols="12" md="10">
          <span class="text--darken-3 inputLabel">Guardian Signature</span>
        </v-col>
        <v-col cols="12" md="10">
          <v-file-input accept="image/*" label="Upload Guardian Signature Photo in JPG or PNG format" solo flat
            :rules="sizeRule" density="compact" outlined append-icon="mdi-paperclip" prepend-icon="" class="text--blue"
            dense v-model="documents.guardianSignature"></v-file-input>
        </v-col>
      </v-row>

      <!-- ? Guardian Passport Size Photo -->
      <v-row dense justify="center">
        <v-col cols="12" md="10">
          <span class="text--darken-3 inputLabel">Guardian Passport Size Photo</span>
        </v-col>
        <v-col cols="12" md="10">
          <v-file-input accept="image/*" label="Upload Guardian Passport Size Photo in JPG or PNG format" solo flat
            :rules="sizeRule" density="compact" outlined append-icon="mdi-paperclip" prepend-icon="" class="text--blue"
            dense v-model="documents.guardianPP"></v-file-input>
        </v-col>
      </v-row>

      <!-- ? PAN Card-->
      <v-row dense justify="center">
        <v-col cols="12" md="10">
          <span class="text--darken-3 inputLabel">PAN Card Image</span>
        </v-col>
        <v-col cols="12" md="10">
          <v-file-input accept="image/*" label="Upload PAN Card Image in JPG or PNG format" solo flat density="compact"
            :rules="sizeRule" outlined append-icon="mdi-paperclip" prepend-icon="" class="text--blue" dense
            v-model="documents.panCardImage"></v-file-input>
        </v-col>
      </v-row>

      <!-- ? Bank Cheque Image-->
      <v-row dense justify="center">
        <v-col cols="12" md="10">
          <span class="text--darken-3 inputLabel">Bank Cheque Image</span>
        </v-col>
        <v-col cols="12" md="10">
          <v-file-input accept="image/*" label="Upload Bank Cheque Image in JPG or PNG format" solo flat density="compact"
            :rules="sizeRule" outlined append-icon="mdi-paperclip" prepend-icon="" class="text--blue" dense
            v-model="documents.bankChequeImage"></v-file-input>
        </v-col>
      </v-row>

      <!-- ? Employee ID Image-->
      <v-row dense justify="center">
        <v-col cols="12" md="10">
          <span class="text--darken-3 inputLabel">Employee ID Image</span>
        </v-col>
        <v-col cols="12" md="10">
          <v-file-input accept="image/*" label="Upload Bank Cheque Image in JPG or PNG format" solo flat density="compact"
            :rules="sizeRule" outlined append-icon="mdi-paperclip" prepend-icon="" class="text--blue" dense
            v-model="documents.employeeIDImage"></v-file-input>
        </v-col>
      </v-row>

      <!-- ? Utility Bill Image-->
      <v-row dense justify="center">
        <v-col cols="12" md="10">
          <span class="text--darken-3 inputLabel">Utility Bill Image (electricity / water bill)</span>
        </v-col>
        <v-col cols="12" md="10">
          <v-file-input accept="image/*" label="Upload Utility Bill Image in JPG or PNG format" solo flat
            density="compact" outlined append-icon="mdi-paperclip" prepend-icon="" class="text--blue" dense
            :rules="sizeRule" v-model="documents.electricityWaterBillImage"></v-file-input>
        </v-col>
      </v-row>

      <!-- ? Passport Image-->
      <v-row dense justify="center">
        <v-col cols="12" md="10">
          <span class="text--darken-3 inputLabel">Passport Image</span>
        </v-col>
        <v-col cols="12" md="10">
          <v-file-input accept="image/*" label="Upload Passport Image in JPG or PNG format" solo flat density="compact"
            :rules="sizeRule" outlined append-icon="mdi-paperclip" prepend-icon="" class="text--blue" dense
            v-model="documents.passportImage"></v-file-input>
        </v-col>
      </v-row>

      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <v-btn color="success" outlined class="mr-4" @click="goBack">
            Back
          </v-btn>
          <v-btn color="success" @click="validate" :loader="submitLoader">Submit</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'Document',
  data() {
    return {
      valid: true,
      documents: {
        photoPP: '',
        map: '',
        guardianSignature: '',
        clientSignature: '',
        rightThumbPrint: '',
        leftThumbPrint: '',
        idCertificateFront: '',
        idCertificateBack: '',
        guardianPP: '',
        panCardImage: '',
        bankChequeImage: '',
        employeeIDImage: '',
        electricityWaterBillImage: '',
        passportImage: '',
      },
      stringRules: [(v) => !!v || 'Field is required'],
      floatRules: [
        (v) => !!v || 'Field is required',
        (v) => /[+-]?([0-9]*[.])?[0-9]+/.test(v) || 'Field must be a number',
      ],
      integerRules: [
        (v) => !!v || 'Field is required',
        (v) =>
          /^[+]?\d+?$/.test(v) || 'Field must be a number without decimals',
      ],
      stringSizeRule: [
        v => !!v && v.size < 1000000 || 'File is required and the file size should not exceed 1MB!'
      ],
      sizeRule: [
        v => (v ? v.size < 1000000 : true) || 'File size should not exceed 1MB!'
      ]
    };
  },
  props: {
    submitLoader: Boolean
  },
  methods: {
    goBack() {
      this.$emit('previousStep');
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit('changeDetails', { data: this.documents, type: "Document Details" });
        this.$emit('submit');
      } else {
        this.$emit('errorMessage', 'Invalid or missing fields');
      }
    },
  },
};
</script>

<style scoped>
.inputLabel {
  font-weight: 600 !important;
  font-size: 0.9rem;
}

.inputTitle {
  font-weight: 600 !important;
  font-size: 1.2rem;
}
</style>
