<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <!-- !  Current Address -->
      <!-- ? Label -->
      <v-row dense justify="center" class="mb-4">
        <v-col cols="12" sm="10" md="10">
          <span class="text--darken-3 inputTitle">Current Address</span>
        </v-col>
      </v-row>

      <!-- ?  Country -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Country
              </span>
            </div>

            <v-autocomplete v-model="address.currentAddress.country_" :items="countries" outlined dense
              :rules="stringRules" label="Select Country"></v-autocomplete>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Province -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Province
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Bagmati" density="compact"
              v-model="address.currentAddress.province_" :rules="stringRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  District -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                District
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Lalitpur" density="compact"
              v-model="address.currentAddress.district_" :rules="stringRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Municipality -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Rural Municipality/ Municipality/ Sub Metropolitan City/
                Metropolitan City <br />
                (गा.पा./ न.पा./ उ.म.न.पा./ म.न.पा.)
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Patan" density="compact"
              v-model="address.currentAddress.municipality_" :rules="stringRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Ward Number -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Ward Number (वडा न.)
              </span>
            </div>

            <v-text-field type="number" placeholder="e.g. 12" v-model="address.currentAddress.wardNo_" density="compact"
              :rules="integerRules" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Tole -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel"> Tole </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Khumaltar" density="compact"
              v-model="address.currentAddress.tole_" :rules="stringRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Telephone No -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Telephone Number
              </span>
            </div>

            <v-text-field type="number" placeholder="e.g. 5534985" v-model="address.currentAddress.telephoneNo_"
              density="compact" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Email -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Email Address
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. example@gmail.com" density="compact"
              v-model="address.currentAddress.email_" :rules="emailRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Mobile No -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Mobile Number
              </span>
            </div>

            <v-text-field type="number" placeholder="e.g. 9827372837" v-model="address.currentAddress.mobileNo_"
              density="compact" :rules="integerRules" />
          </section>
        </v-col>
      </v-row>

      <!-- !  Permanent Address -->
      <!-- ? Label -->
      <v-row dense justify="center" class="mb-4">
        <v-col cols="12" sm="10" md="10">
          <span class="text--darken-3 inputTitle">Permanent Address</span>
        </v-col>
      </v-row>

      <!-- ?  Province -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Province
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Bagmati" density="compact"
              v-model="address.permanentAddress.province" :rules="stringRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  District -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                District
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Lalitpur" density="compact"
              v-model="address.permanentAddress.district" :rules="stringRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Municipality -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Municipality
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Patan" density="compact"
              v-model="address.permanentAddress.municipality" :rules="stringRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Tole -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel"> Tole </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Khumaltar" density="compact"
              v-model="address.permanentAddress.tole" :rules="stringRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Telephone No -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Telephone Number
              </span>
            </div>

            <v-text-field type="number" placeholder="e.g. 5534985" v-model="address.permanentAddress.telephoneNo"
              density="compact" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Ward Number -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Ward Number
              </span>
            </div>

            <v-text-field type="number" placeholder="e.g. 12" v-model="address.permanentAddress.wardNo" density="compact"
              :rules="integerRules" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Block Number -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Block Number
              </span>
            </div>

            <v-text-field type="number" placeholder="e.g. 12" v-model="address.permanentAddress.blockNo"
              density="compact" />
          </section>
        </v-col>
      </v-row>

      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <v-btn color="success" outlined class="mr-4" @click="goBack">Back</v-btn>
          <v-btn color="success" @click="validate">Next</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { getNames } from 'country-list';

export default {
  name: 'Address',

  data() {
    return {
      valid: true,
      countries: getNames(),
      address: {
        currentAddress: {
          country_: '',
          province_: '',
          district_: '',
          municipality_: '',
          wardNo_: '',
          tole_: '',
          telephoneNo_: '',
          email_: '',
          mobileNo_: '',
        },
        permanentAddress: {
          province: '',
          district: '',
          municipality: '',
          tole: '',
          telephoneNo: '',
          wardNo: '',
          blockNo: '',
        },
      },
      stringRules: [(v) => !!v || 'Field is required'],
      emailRules: [
        (v) => !!v || 'Field is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      floatRules: [
        (v) => !!v || 'Field is required',
        (v) => /[+-]?([0-9]*[.])?[0-9]+/.test(v) || 'Field must be a number',
      ],
      integerRules: [
        (v) => !!v || 'Field is required',
        (v) =>
          /^[+]?\d+?$/.test(v) || 'Field must be a number without decimals',
      ],
    };
  },

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit('changeDetails', { data: this.address, type: "Address Details" });
        this.$emit('nextStep');
      } else {
        this.$emit('errorMessage', 'Invalid or missing fields');
      }
    },
    goBack() {
      this.$emit('previousStep');
    },
  },
};
</script>

<style>
.inputLabel {
  font-weight: 600 !important;
  font-size: 0.9rem;
}

.inputTitle {
  font-weight: 600 !important;
  font-size: 1.2rem;
}
</style>
