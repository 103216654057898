<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <!-- !  familyMembers Members -->
      <!-- ? Label -->
      <v-row dense justify="center" class="mb-4">
        <v-col cols="12" sm="10" md="10">
          <span class="text--darken-3 inputTitle"
            >Details of Family Members</span
          >
        </v-col>
      </v-row>

      <!-- ?  Grand Father Name -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Grand Father's Name
              </span>
            </div>

            <v-text-field
              variant="outlined"
              placeholder="e.g. Ram Bahadur Amatya"
              density="compact"
              v-model="familyMembers.grandFatherName"
              :rules="stringRules"
            ></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Grand Father Name in Nepali -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Grand Father's Name in Nepali (बजेको नाम)
              </span>
            </div>

            <v-text-field
              placeholder="e.g. राम बहादुर अमात्य"
              v-model="familyMembers.grandFatherNameNP"
              density="compact"
              :rules="stringRules"
            />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Father Name -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Father's Name
              </span>
            </div>

            <v-text-field
              variant="outlined"
              placeholder="e.g. Ram Bahadur Amatya"
              density="compact"
              v-model="familyMembers.fatherName"
              :rules="stringRules"
            ></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?   Father Name in Nepali -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Father's Name in Nepali
              </span>
            </div>

            <v-text-field
              placeholder="e.g. राम बहादुर अमात्य"
              v-model="familyMembers.fatherNameNP"
              density="compact"
              :rules="stringRules"
            />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Mother Name -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Mother's Name
              </span>
            </div>

            <v-text-field
              variant="outlined"
              placeholder="e.g. Sita Bahadur Amatya"
              density="compact"
              v-model="familyMembers.motherName"
              :rules="stringRules"
            ></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Mother Name in Nepali -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Mother's Name in Nepali
              </span>
            </div>

            <v-text-field
              placeholder="e.g. सिता बहादुर अमात्य"
              v-model="familyMembers.motherNameNP"
              :rules="stringRules"
            />
          </section>
        </v-col>
      </v-row>

      <!-- ? Spouse Name -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Spouse's Name
              </span>
            </div>

            <v-text-field
              variant="outlined"
              placeholder="e.g. Sita Bahadur Amatya"
              density="compact"
              v-model="familyMembers.spouseName"
            ></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Mother Name in Nepali -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Spouse's Name in Nepali
              </span>
            </div>

            <v-text-field
              placeholder="e.g. सिता बहादुर अमात्य"
              v-model="familyMembers.spouseNameNP"
            />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Son Name -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Son's Name
              </span>
            </div>

            <v-text-field
              variant="outlined"
              placeholder="e.g. Ram Bahadur Amatya"
              v-model="familyMembers.sonName"
              hint="* Use comma to separate multiple names. e.g. Ram Bahadur Amatya, Shyam Bahadur Amatya"
            ></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Son Name in Nepali -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Son's Name in Nepali
              </span>
            </div>

            <v-text-field
              placeholder="e.g. राम बहादुर अमात्य"
              v-model="familyMembers.sonNameNP"
              hint="* Use comma to separate multiple names. e.g. राम बहादुर अमात्य, श्याम बहादुर अमात्य"
            />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Daughter Name -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Daughter's Name
              </span>
            </div>

            <v-text-field
              variant="outlined"
              placeholder="e.g. Sita Amatya"
              v-model="familyMembers.daughterName"
              hint="* Use comma to separate multiple names. e.g. Sita Amatya, Gita Amatya"
            ></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Daughter Name in Nepali -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Daughter's Name in Nepali
              </span>
            </div>

            <v-text-field
              placeholder="e.g. सिता बहादुर अमात्य"
              v-model="familyMembers.daughterNameNP"
              hint="* Use comma to separate multiple names. e.g. सिता बहादुर अमात्य, गीता बहादुर अमात्य"
            />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Father in law Name -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Father in Law's Name (IN CASE OF MARRIED WOMEN)
              </span>
            </div>

            <v-text-field
              variant="outlined"
              placeholder="e.g. Hari Amatya"
              v-model="familyMembers.fatherInLawName"
            ></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Father in law in Nepali -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Father in Law's Name in Nepali
              </span>
            </div>

            <v-text-field
              placeholder="e.g. हरि बहादुर अमात्य"
              v-model="familyMembers.fatherInLawNameNP"
            />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Mother In Law Name -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Mother in Law's Name (सासुको नाम (विवाहित महिलाको हकमा))
              </span>
            </div>

            <v-text-field
              variant="outlined"
              placeholder="e.g. Sita Amatya"
              v-model="familyMembers.motherInLawName"
            ></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Mother in Law Name in Nepali -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Mother in Law's Name in Nepali (सासुको नाम (विवाहित महिलाको
                हकमा))
              </span>
            </div>

            <v-text-field
              placeholder="e.g. सिता बहादुर अमात्य"
              v-model="familyMembers.motherInLawNameNP"
            />
          </section>
        </v-col>
      </v-row>

      <!-- !  familyMembers Members -->
      <!-- ? Label -->
      <v-row dense justify="center" class="mb-4">
        <v-col cols="12" sm="10" md="10">
          <span class="text--darken-3 inputTitle"
            >संरक्षकको विवरण (नाबालक को हकमा मात्र)</span
          >
          <br />
          <span class="text--darken-3 inputTitle">
            Guardian’s Details (In case of Minor only)</span
          >
        </v-col>
      </v-row>

      <!-- ?  Guardians Name -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Name/Surname (नाम/ थर)
              </span>
            </div>

            <v-text-field
              placeholder="e.g. Rukhmani Amatya"
              v-model="familyMembers.guardianName"
            />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Guardians Name Nepali -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Name/Surname (नाम/ थर) in Nepali
              </span>
            </div>

            <v-text-field
              placeholder="e.g. सिता बहादुर अमात्य"
              v-model="familyMembers.guardianNameNP"
            />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Guardians Relationship -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Relationship with applicant (निवेदकसंगको सम्बन्ध)
              </span>
            </div>

            <v-text-field
              placeholder="e.g. Mother"
              v-model="familyMembers.guardianRelation"
            />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Guardians Address -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Correspondence Address (पत्राचार ठेगाना)
              </span>
            </div>

            <v-text-field
              placeholder="e.g. Mangalbazar, Lalitpur"
              v-model="familyMembers.guardianCorrespondenceAddress"
            />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Guardian Country -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Country (देश)
              </span>
            </div>

            <v-autocomplete
              v-model="familyMembers.guardianCountry"
              :items="countries"
              outlined
              dense
              label="Select Country"
            ></v-autocomplete>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Province -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Province
              </span>
            </div>

            <v-text-field
              variant="outlined"
              placeholder="e.g. Bagmati"
              density="compact"
              v-model="familyMembers.guardianProvince"
            ></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  District -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                District
              </span>
            </div>

            <v-text-field
              variant="outlined"
              placeholder="e.g. Lalitpur"
              density="compact"
              v-model="familyMembers.guardianDistrict"
            ></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Municipality -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Rural Municipality/ Municipality/ Sub Metropolitan City/
                Metropolitan City <br />
                (गा.पा./ न.पा./ उ.म.न.पा./ म.न.पा.)
              </span>
            </div>

            <v-text-field
              variant="outlined"
              placeholder="e.g. Patan"
              density="compact"
              v-model="familyMembers.guardianMunicipality"
            ></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Ward Number -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Ward Number (वडा न.)
              </span>
            </div>

            <v-text-field
              type="number"
              placeholder="e.g. 12"
              v-model="familyMembers.guardianWardNumber"
              density="compact"
            />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Telephone no  -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Telephone No. (टेलिफोन नं.)
              </span>
            </div>

            <v-text-field
              type="number"
              placeholder="e.g. 12"
              v-model="familyMembers.guardianTelephoneNo"
              density="compact"
            />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Fax no  -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Fax No. (फ्याक्स नं.)
              </span>
            </div>

            <v-text-field
              type="number"
              placeholder="e.g. 12"
              v-model="familyMembers.guardianFaxNo"
              density="compact"
            />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Mobile no  -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Mobile No. (मोबाईल नं.)
              </span>
            </div>

            <v-text-field
              type="number"
              placeholder="e.g. 12"
              v-model="familyMembers.guardianMobileNo"
              density="compact"
            />
          </section>
        </v-col>
      </v-row>

      <!-- ?  PAN no  -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                PAN No. (स्थायी लेखा नम्बर)
              </span>
            </div>

            <v-text-field
              type="number"
              placeholder="e.g. 12"
              v-model="familyMembers.guardianPANNo"
              density="compact"
            />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Email Address  -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                E-Mail ID (ईमेल)
              </span>
            </div>

            <v-text-field
              type="email"
              placeholder="e.g. ramamatya@gmail.com"
              v-model="familyMembers.guardianEmailId"
              density="compact"
            />
          </section>
        </v-col>
      </v-row>

      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <v-btn color="success" outlined class="mr-4" @click="goBack"
            >Back</v-btn
          >
          <v-btn color="success" @click="validate">Next</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { getNames } from 'country-list';
export default {
  name: 'FamilyMembers',

  data() {
    return {
      valid: true,
      countries: getNames(),
      familyMembers: {
        grandFatherName: '',
        grandFatherNameNP: '',
        fatherName: '',
        fatherNameNP: '',
        motherName: '',
        motherNameNP: '',
        spouseName: '',
        spouseNameNP: '',
        sonName: '',
        sonNameNP: '',
        daughterName: '',
        daughterNameNP: '',
        fatherInLawName: '',
        fatherInLawNameNP: '',
        motherInLawName: '',
        motherInLawNameNP: '',
        daughterInLawNameNP: '',
        guardianName: '',
        guardianNameNP: '',
        guardianRelation: '',
        guardianCorrespondenceAddress: '',
        guardianCountry: '',
        guardianProvince: '',
        guardianDistrict: '',
        guardianMunicipality: '',
        guardianWardNo: '',
        guardianTelephoneNo: '',
        guardianFaxNo: '',
        guardianMobileNo: '',
        guardianPANNo: '',
        guardianEmailId: '',
      },
      stringRules: [(v) => !!v || 'Field is required'],
      floatRules: [
        (v) => !!v || 'Field is required',
        (v) => /[+-]?([0-9]*[.])?[0-9]+/.test(v) || 'Field must be a number',
      ],
      integerRules: [
        (v) => !!v || 'Field is required',
        (v) =>
          /^[+]?\d+?$/.test(v) || 'Field must be a number without decimals',
      ],
    };
  },

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit('changeDetails', {
          data: this.familyMembers,
          type: 'Family Member Details',
        });
        this.$emit('nextStep');
      } else {
        this.$emit('errorMessage', 'Invalid or missing fields');
      }
    },
    goBack() {
      this.$emit('previousStep');
    },
  },
};
</script>

<style>
.inputLabel {
  font-weight: 600 !important;
  font-size: 0.9rem;
}

.inputTitle {
  font-weight: 600 !important;
  font-size: 1.2rem;
}
</style>
